/** @jsx jsx */
import Link from 'next/link'
import { jsx, Box, Text } from 'theme-ui'

const FourOhFour = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: 'red',
        padding: '20px',
      }}
    >
      <Text variant="copy">
        404 - Page Not Found <br />
      </Text>
      <Link href={'/'} scroll={false}>
        <a>Return Home</a>
      </Link>
    </Box>
  )
}

export default FourOhFour
